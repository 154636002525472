<template>
  <div class="statistics" v-loading="loading">
    <comp-part title="数据统计"></comp-part>
    <div
    v-if="queryHandle"
      style="display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      margin: 12px 0 30px;">
      <div style="flex: 1;flex-wrap: nowrap;">
        <p style="display: inline-block; width: 70px">选择时间：</p>

        <el-date-picker
          size="small"
          v-model="timeRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="timestamp"
          style="min-width: 424px">
        </el-date-picker>
        <br>
        <p style="display: inline-block; width: 70px">年龄：</p>
        <el-input-number size="small" class="form-ipt" placeholder="请输入年龄" v-model="searchData.ageFrom"
        :min="1"
        :max="110"
        controls-position="right"></el-input-number>
        -
        <el-input-number size="small" class="form-ipt" placeholder="请输入年龄" v-model="searchData.ageTo"
        :min="1"
        :max="110"
        controls-position="right"></el-input-number>
      </div>
      <div style="flex: 1; flex-wrap: nowrap;">
        <p style="display: inline-block; width: 70px">病历号：</p>
        <el-select
        size="small"
        v-model="patientIdList"
        multiple
        collapse-tags
        filterable
        :filter-method="dataFilter"
        placeholder="请输入病历号"
        style="width: 80%;">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <br>
        <p style="display: inline-block;  width: 70px;">性别：</p>
        <el-select
        style="margin-top: 8px"
        v-model="searchData.gender"
        size="small"
        placeholder="请选择性别"
        clearable>
          <el-option
            v-for="item in genderEnums"
            :key="item.code"
            :label="item.text"
            :value="item.code">
          </el-option>
        </el-select>
      </div>
      <div style="flex: 0.1; flex-wrap: nowrap;">
        <el-button
        type="primary"
        size="small"
        style="width:120px;
        display: inline-block;
        margin-right: 0px"
        @click="onSearch">
        查询
        </el-button>
      </div>
    </div>
    <div v-if="result === 1" style="display: flex; ">
      <span style="float:left;color: #606266; width:92%">
      本次查询有{{totalPage}}条数据源，
      时间范围
      {{moment.unix(searchData.timeFrom / 1000).format('Y-MM-DD')}}
      -
      {{moment.unix(searchData.timeTo / 1000).format('Y-MM-DD')}}</span>
      <el-button style="float:right;" @click="exportExcel">导出Excel</el-button>
    </div>
    <div>
      <el-table
      v-if="result === 1"
      :data="tableData">
        <el-table-column
          align="center"
          fixed
          label="序号"
          type="index"
          width="50">
        </el-table-column>
        <el-table-column
          align="center"
          fixed
          prop="patientName"
          label="患者姓名"
          width="100">
        </el-table-column>
        <el-table-column
          align="center"
          fixed
          prop="recordNo"
          label="病案号">
        </el-table-column>
        <el-table-column
          prop="gender"
          label="性别">
            <template slot-scope="scope">
              <span>{{scope.row.gender === 1 ? '男': '女'}}</span>
            </template>
        </el-table-column>
        <el-table-column
          prop="age"
          label="年龄">
        </el-table-column>
        <el-table-column
          align="center"
          prop="visitTime"
          label="随访日期"
          width="150">
            <template slot-scope="scope">
              <span>{{moment.unix(scope.row.visitTime / 1000).format('Y-MM-DD')}}</span>
            </template>
        </el-table-column>
        <el-table-column
          prop="brandName"
          label="品牌">
        </el-table-column>
        <el-table-column
          prop="modelName"
          label="型号">
        </el-table-column>
        <el-table-column
          prop="batteryVoltage"
          label="电压">
           <template slot-scope="scope">
             <span>{{scope.row.params.batteryVoltage}}</span>
           </template>
        </el-table-column>
        <el-table-column
          prop="remainingLongevity"
          label="寿命">
            <template slot-scope="scope">
             <span>{{scope.row.params.remainingLongevity}}</span>
           </template>
        </el-table-column>
        <el-table-column
          prop="mode"
          label="模式">
            <template slot-scope="scope">
             <span>{{scope.row.params.mode}}</span>
           </template>
        </el-table-column>
        <el-table-column
          prop="lowerRate"
          label="基本频率">
            <template slot-scope="scope">
             <span>{{scope.row.params.lowerRate}}</span>
           </template>
        </el-table-column>
        <el-table-column
          prop="totalAP"
          label="AP占比">
            <template slot-scope="scope">
             <span v-if="scope.row.params.totalAP">{{scope.row.params.totalAP}}</span>
             <span v-else>-</span>
           </template>
        </el-table-column>
        <el-table-column
          prop="totalVP"
          label="VP占比">
            <template slot-scope="scope">
             <span v-if="scope.row.params.totalVP">{{scope.row.params.totalVP}}</span>
             <span v-else>-</span>
           </template>
        </el-table-column>
        <el-table-column
          prop="rvThreshold"
          label="RA 夺获阈值"
          width="150">
            <template slot-scope="scope">
             <span v-if="scope.row.params.rvThreshold">{{scope.row.params.rvThreshold}}</span>
             <span v-else>-</span>
           </template>
        </el-table-column>
        <el-table-column
          prop="atrialProgrammedSensitivity"
          label="RA 感知">
            <template slot-scope="scope">
             <span v-if="scope.row.params.atrialProgrammedSensitivity">{{scope.row.params.atrialProgrammedSensitivity}}</span>
             <span v-else>-</span>
           </template>
        </el-table-column>
        <el-table-column
          prop="atrialLeadPacingImpedance"
          label="RA 电极导线阻抗"
          width="150">
            <template slot-scope="scope">
             <span v-if="scope.row.params.atrialLeadPacingImpedance">{{scope.row.params.atrialLeadPacingImpedance}}</span>
             <span v-else>-</span>
           </template>
        </el-table-column>
        <el-table-column
          prop="rvThreshold"
          label="RV 夺获阈值"
          width="150">
            <template slot-scope="scope">
             <span v-if="scope.row.params.rvThreshold">{{scope.row.params.rvThreshold}}</span>
             <span v-else>-</span>
           </template>
        </el-table-column>
        <el-table-column
          prop="rvProgrammedSensitivity"
          label="RV 感知">
            <template slot-scope="scope">
             <span v-if="scope.row.params.rvProgrammedSensitivity">{{scope.row.params.rvProgrammedSensitivity}}</span>
             <span v-else>-</span>
           </template>
        </el-table-column>
        <el-table-column
          prop="rvLeadPacingImpedance"
          label="RV 电极导线阻抗"
          width="150">
            <template slot-scope="scope">
             <span v-if="scope.row.params.rvLeadPacingImpedance">{{scope.row.params.rvLeadPacingImpedance}}</span>
             <span v-else>-</span>
           </template>
        </el-table-column>
        <el-table-column
          prop="lvThreshold"
          label="LV 夺获阈值"
          width="150">
            <template slot-scope="scope">
             <span v-if="scope.row.params.lvThreshold">{{scope.row.params.lvThreshold}}</span>
             <span v-else>-</span>
           </template>
        </el-table-column>
        <el-table-column
          prop="lvProgrammedSensitivity"
          label="LV 感知">
            <template slot-scope="scope">
             <span v-if="scope.row.params.lvProgrammedSensitivity">{{scope.row.params.lvProgrammedSensitivity}}</span>
             <span v-else>-</span>
           </template>
        </el-table-column>
        <el-table-column
          prop="lvLeadPacingImpedance"
          label="LV 电极导线阻抗"
          width="150">
            <template slot-scope="scope">
             <span v-if="scope.row.params.lvLeadPacingImpedance">{{scope.row.params.lvLeadPacingImpedance}}</span>
             <span v-else>-</span>
           </template>
        </el-table-column>
      </el-table>
      <el-empty
        v-if="result === 0"
        class="abnormal-page"
        description="暂无数据">
      </el-empty>
      <!-- <el-empty
        v-if="result === 2"
        image="https://f-u-system.oss-cn-beijing.aliyuncs.com/page404.png">
        <el-button type="primary" @click="onSearch">刷新</el-button>
      </el-empty> -->
      <div style="height: 80px"></div>
      <div class="paginationClass" v-if="tableData.length > 0">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="searchData.pageNum"
        :page-size="searchData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import CompPart from '@/components/Part'
import { genderEnums } from '@/utils/enums'
import { getDataReportList, getPatientName, getReportExcel } from '@/service/module/datareport'
const moment = require('moment')
export default {
  components: {
    CompPart
  },
  data () {
    return {
      moment: moment,
      genderEnums, // 性别枚举
      searchData: { // 搜索数据
        timeFrom: null, // 起始时间
        timeTo: null, // 结束时间
        patientIds: '', // 病历号
        gender: null, // 性别
        // ageFrom: null, // 年龄范围（起）
        // ageTo: null, // 年龄范围（止）
        pageNum: 1, // 当前页
        pageSize: 20 // 每页条数
      },
      patientIdList: [], // 搜索-病理号列表
      timeRange: [], // 搜索-时间列表

      totalPage: 0,
      tableData: [],
      loading: false, // loading
      options: [], // 患者列表
      result: 0, // 列表接口返回结果

      queryHandle: false
    }
  },
  mounted () {
    this._send('$pageView', '数据统计进入情况', '数据统计页面 加载成功时触发', {
      accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
      doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
      hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
    })
    const authList = JSON.parse(sessionStorage.getItem('auth')).perms
    authList.find(i => {
      if (i === 'statistics:query') {
        this.queryHandle = true
      }
    })
  },
  methods: {
    handleSizeChange (val) {
      this.searchData.pageSize = val
      this.getDataReportList(this.searchData)
    },
    handleCurrentChange (val) {
      this.searchData.pageNum = val
      this.getDataReportList(this.searchData)
    },
    onSearch () {
      this.getDataReportList(this.searchData)
    },
    exportExcel () {
      getReportExcel(this.searchData, {
        responseType: 'blob'
      }).then(res => {
        console.log('文件内容：', res)
        const blob = new Blob([res])// 转换数据类型

        const downloadElement = document.createElement('a')

        const href = window.URL.createObjectURL(blob) // 创建下载的链接

        console.log(blob)
        downloadElement.href = href
        downloadElement.download = `exportData${moment.unix(new Date().getTime() / 1000).format('Y-MM-DD HH:mm:ss')}.xlsx` // 下载后文件名
        console.log(href)
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
        // const a = document.createElement('a') // 创建一个a标签用于下载
        // a.download = moment.unix(new Date().getTime() / 1000).format('Y-MM-DD') + '.xlsx'
        // a.href = window.URL.createObjectURL(blob)
        // a.click()
        // a.remove()
      })
    },
    getDataReportList (params) {
      this.loading = true
      this.searchData.timeFrom = this.timeRange[0]
      this.searchData.timeTo = this.timeRange[1]
      this.searchData.patientIds = this.patientIdList.join()

      getDataReportList(params).then(res => {
        this.loading = false
        if (res.data.results.length > 0) {
          this.result = 1
          this.searchData.pageNum = res.data.currentPage
          this.searchData.pageSize = res.data.pageSize
          this.totalPage = res.data.total
          this.tableData = res.data.results
        } else {
          this.result = 0
        }
      }).catch(() => {
      })
    },
    dataFilter (val) {
      if (val.length > 4) {
        getPatientName(val).then(res => {
          if (res.data) {
            this.options = res.data
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form {
  width: 100%;
}
.statistics {
  padding: 0 20px;
}
.el-input {
  display: inline-block;
  width: 40%;
}
.form-ipt {
  width: 206px;
  margin-top: 8px
}
.paginationClass {
background: #fff;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 210px;
    z-index: 100;
    padding: 24px 20px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.abnormal-page {
  width: 100%;
  height
  : 500px;
}
::v-deep .el-table th.el-table__cell>.cell {
  color: #909399;
  font-weight: 400;
  font-size: 14px;
 }
</style>
