import { get } from '@http/axios'

// 获取数据统计列表
export const getDataReportList = (params) => get('/api/doctor-platform/followup/stats/report-data', { params })

// 根据病案号查询患者
export const getPatientName = (recordNo) => get(`/api/doctor-platform/followup/patient/by-record-no?recordNo=${recordNo}`)

// 导出统计数据excel
export const getReportExcel = (params, config) => get('/api/doctor-platform/followup/stats/export-report-data', { params, ...config })
